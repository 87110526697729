import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DeviceprofileService {

  constructor(
    public afs: AngularFirestore,
  ) { }

  updateDeviceProfileDoc(
    accuracy: string,
    altitude: string,
    latitude: string,
    longitude: string,
    serialnum: string,
  ): Promise<void> {
    return this.afs.doc(`deviceprofile/${serialnum}`).set({
      accuracy,
      altitude,
      latitude,
      longitude,
      serialnum
    }, {merge: true});
  }

  shareDeviceLookupRecipient(emailId) {
    return this.afs.collection('userprofile', ref => ref.where('email', '==', emailId));
  }
  addDeviceToUser(userId, deviceId) {
    return this.afs.collection('userprofile').doc(userId).set({
      devices: firestore.FieldValue.arrayUnion(deviceId)
    }, {merge: true});
  }
} // closes class
