import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Platform } from '@ionic/angular';
import { LoadingService } from '../_services/loading.service';
import { AlertService } from '../_services/alert.service';
import { HttpClient  } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: Observable<firebase.User>;
  public isAuthenticated: boolean;
  public userId: string;
  public userEmail: string;
  public uid: Observable<string>;

  constructor(
    private afAuth: AngularFireAuth,
    private gplus: GooglePlus,
    private platform: Platform,
    private router: Router,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private afs: AngularFirestore
  ) {
    this.user = this.afAuth.authState;
    // console.log(`authState: ${JSON.stringify(this.afAuth.authState)}`);
    this.getUserId();


  } // closes constructor


  async getUserId() {
    this.afAuth.auth.onAuthStateChanged(authuser => {
      if (authuser) {
        this.userId = authuser.uid;
        this.userEmail = authuser.email;
        // console.log(`authService userID: ${this.userId}`);
        // console.log(`logged in: ${authuser.email} | ${authuser.uid}`);
      } else {
        console.log(`not logged in`);
        this.userId = null;
        return null;
      }
    });
  }

  getUser() {
    return new Promise( (resolve, reject) => {
      this.afAuth.authState.subscribe(
        (user) => {
          if (user != null) {
            this.userId = user.uid;
            this.isAuthenticated = true;
            resolve(this.isAuthenticated);
          } else {
            this.isAuthenticated = false;
            reject(this.isAuthenticated);
          }
        },
        (error) => {
          this.isAuthenticated = false;
          reject(this.isAuthenticated);
          console.log(error);
        }
      );
    });
  }


  async getAuthState() {
    await this.afAuth.auth.onAuthStateChanged(authuser => {
      if (authuser) {
        console.log(`logged in: ${authuser.email} | ${authuser.uid}`);
        return this.isAuthenticated = true;
      } else {
        console.log(`not logged in`);
        return this.isAuthenticated = false;
      }
    });
  }

  getIPAddress(emailId, functionName) {
    return this.http.get('https://api.ipify.org/?format=json').subscribe((result: any) => {
      let appPlatform = '';
      if (this.platform.is('android')) { appPlatform = 'android'; }
      if (this.platform.is('ios')) { appPlatform = 'ios'; }
      if (this.platform.is('desktop')) { appPlatform = 'desktop'; }

      this.afs.collection('userloginhistory').add({
        ip: result.ip,
        email: emailId,
        loginUrl: window.location.href,
        createdAt: parseFloat((Date.now() / 1000).toFixed(0)),
        type: functionName,
        platform: appPlatform,
        appVersion: environment.appVersion
      });

    });

  }

// ! Begin authentication fuctions
  tryGoogleLogin() {
    if (this.platform.is('cordova')) {
      this.tryGoogleNativeLogin();
    } else {
      this.tryGoogleWebLogin();
    }
  }
  async tryGoogleWebLogin(): Promise<void> {
    try {
      console.log('tryGoogleWebLogin');
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      await this.afAuth.auth.signInWithPopup(provider).then( theUser => {
        this.getIPAddress(theUser.user.email, 'googleWeb');
        this.router.navigate(['/']);
      });
    } catch (err) {
      console.log(err);
    }
  }
  async tryGoogleNativeLogin(): Promise<void>  {
    console.log('tryGoogleNativeLogin');
    try {
      const gplusUser = await this.gplus.login({
        webClientId: '632373897129-ivmejofbkgv4lc71g7eu0ndpoeb93p39.apps.googleusercontent.com',
        offline: true,
        scopes: 'profile email'
      });
      await this.afAuth.auth.signInWithCredential(
        firebase.auth.GoogleAuthProvider.credential(gplusUser.idToken)).then( theUser => {
          this.getIPAddress(theUser.user.email, 'googleNative');
          this.router.navigate(['/']);
        });
    } catch (err) {
      console.log(err);
    }
  }
  async tryEmailLogin(value) {
    try {
      await this.loadingService.startLoading();
      await this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password).then(async (result) => {
        await this.loadingService.stopLoading();
        this.getIPAddress(value.email, 'email');
        this.router.navigate(['/']);
      });
      } catch (error) {
        await this.loadingService.stopLoading();
        this.alertService.createAlert('Login Failed', 'Check your password and try again', 'Dismiss');
      }

  }
  async trySignout() {
    await this.afAuth.auth.signOut();
    this.user = null;
    this.userId = null;
    window.location.reload();
    // this.router.navigate(['landing']);
  }
  async tryRegister(value) {
    console.log(value.password + value.passwordConfirm);
    if (value.password === value.passwordConfirm ) {
    try {
      await this.afAuth.auth.createUserWithEmailAndPassword(value.email, value.password).then(() => {
        const user = firebase.auth().currentUser;
        user.sendEmailVerification().then(() => {
          this.getIPAddress(value.email, 'register');
          this.alertService.createAlert(
            'Success!',
            'Check your email to confirm your registration',
            'Dismiss');
        });
      });
    } catch (error) {
      this.alertService.createAlert(
        'Error',
        `Something went wrong. ${error}`,
        'Dismiss');
    }
    } else {
      this.alertService.createAlert(
        'Error',
        'Your passwords do not match',
        'Dismiss'
      );
    }
  }
  async tryPasswordReset(value) {
    try {
      await this.afAuth.auth.sendPasswordResetEmail(value.email).then(() => {
        this.alertService.createAlert(
          'Success',
          'Check your email for your password reset link.',
          'Dismiss'
        );
      });
    } catch (error) {
      this.alertService.createAlert(
        'Oops',
        `Something went wrong.  ${error}`,
        'Dismiss');
    }
  }
// ! End authentication functions
} // closes class
