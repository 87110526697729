import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ProComponent } from '../_components/waterquality/pro/pro.component';
import { BaseComponent } from '../_components/waterquality/base/base.component';
import { DevicesharingComponent } from '../_components/form/devicesharing/devicesharing.component';
import { TooltipComponent } from '../_components/modals/tooltip/tooltip.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxChartsModule } from '@swimlane/ngx-charts'; // pending--remove
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    ProComponent,
    BaseComponent,
    DevicesharingComponent,
    TooltipComponent

  ],
  exports: [
    ProComponent,
    BaseComponent,
    DevicesharingComponent,
    TooltipComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    NgxChartsModule, // pending--remove
    ChartsModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      toFixed: 1,
      maxPercent: 100,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      animationDuration: 1000,
      showBackground: false,
      showSubtitle: false,
      responsive: true,
      backgroundPadding: 0
    })
  ],
  entryComponents: [
    TooltipComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
