import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevicemeasurementsService {

  constructor(
    public afs: AngularFirestore
  ) {
  }

  getDeviceMeasurementsLastNHours(deviceId, lastHoursNumber): Observable<any> {
    const queryLimit = new Date();
    queryLimit.setHours(queryLimit.getHours() - lastHoursNumber);
    queryLimit.toISOString().replace('T', ' ').replace('Z', '');
    return this.afs.collection('devicemeasurements', ref =>
      ref
      .where('device', '==', deviceId)
      .where('timestamp', '>', queryLimit)
      .orderBy('timestamp', 'asc')
    ).valueChanges();
  }
} // closes class
