import { version } from '../../package.json';

export const environment = {
  production: true,
  firebaseConfig:
  {
    apiKey: 'AIzaSyDeBf2vo1mXA2p3IV9gitncO8DjeXeZu2I',
    authDomain: 'waterbotconsumer.firebaseapp.com',
    databaseURL: 'https://waterbotconsumer.firebaseio.com',
    projectId: 'waterbotconsumer',
    storageBucket: 'waterbotconsumer.appspot.com',
    messagingSenderId: '632373897129'
  },
  appVersion: version
};
