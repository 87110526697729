import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceprofileService } from '../../../_services/deviceprofile.service';
import { AlertService } from '../../../_services/alert.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-devicesharing',
  templateUrl: './devicesharing.component.html',
  styleUrls: ['./devicesharing.component.scss'],
})
export class DevicesharingComponent implements OnInit {
  @Input() deviceId;

  deviceSharingForm: FormGroup;
  emailRegex = /^[a-z_\-0-9\.\*\#\$\!\~\%\^\&\-\+\?\|]+@+[a-z\-0-9]+\.[a-z]{2,4}$/i;
  constructor(
    public deviceProfileService: DeviceprofileService,
    public fb: FormBuilder,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.createDeviceShareForm();
  }

  createDeviceShareForm() {
    this.deviceSharingForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
    });
  }

  shareDevice() {
    const emailId = this.deviceSharingForm.value.email;
    this.deviceProfileService.shareDeviceLookupRecipient(emailId).valueChanges({idField: 'id'}).pipe(take(1)).subscribe(snapshot => {
        if (snapshot.length === 0 ) {
          this.alertService.createAlert(
            `User Not Found`,
            `There is no user with the email ${emailId}.  Invite them to download the app and try again.`,
            `Dismiss`);
        } else {
          this.deviceProfileService.addDeviceToUser(snapshot[0].id, this.deviceId);
          this.alertService.createAlert(`Success`, `${emailId} can now access this WaterBot`, `Ok`);
        }
    });
    this.deviceSharingForm.reset();
  }


} // closes class
