import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TooltipService } from '../../../_services/tooltip.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {

  slug: string;
  tooltipDocument: any;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    public tooltipService: TooltipService
  ) { }

  ionViewWillEnter() {
    this.slug = this.navParams.get('slug');
    console.log(this.slug);
    // Add some logic for if not exists
    this.tooltipDocument = this.tooltipService.getTooltipBySlug(this.slug).valueChanges();
  }
  ngOnInit() {}

  async closeModal() {
    await this.modalController.dismiss();
  }
}
