import { Component, OnInit, AfterContentInit, Input, OnDestroy } from '@angular/core';
import { DevicecarddataService } from '../../../_services/devicecarddata.service';
import { DevicemeasurementsdailyService } from '../../../_services/devicemeasurementsdaily.service';
import { Devicemeasurementsdailysummary } from '../../../_interfaces/devicemeasurementsdailysummary';
import { LoadingService } from '../../../_services/loading.service';
import { Observable, Subscription} from 'rxjs';

// For ng2-charts
import { ChartDataSets, ChartOptions, ChartData } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html',
  styleUrls: ['./pro.component.scss'],
})
export class ProComponent implements OnInit, AfterContentInit, OnDestroy {
  public subscription: Subscription = new Subscription();
  @Input() data;
  @Input() device;
  deviceData: any;
  deviceId: any;
  success: string;
  successTint: string;
  successShade: string;
  getData: any;
  cond1Data: ChartDataSets[] = [];
  cond2Data: ChartDataSets[] = [];
  chartLabels: Label[] = [];
  //
  showCharts: boolean; // display the charts
  showChartsPending: boolean; // show data pending if not sufficient data

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'white',
          }
        }
      ]
    },
    annotation: {

    },
  };
  public lineChartColors: Color[] = [
    // graphData array is min, ave, max...so colors are green, gray, red
    { // minimum condition => green
      backgroundColor: 'transparent',
      borderColor: 'rgba(0,125,0,1)',
      pointBackgroundColor: 'rgba(0,125,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,125,0,0.8)'
    },
    { // average condition => gray
      backgroundColor: 'transparent',
      borderColor: 'rgba(204,204,204,1)',
      pointBackgroundColor: 'rgba(204,204,204,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(204,204,204,1)'
    },
    { // max condition => red
      backgroundColor: 'transparent',
      borderColor: 'rgba(125,0,0,1)',
      pointBackgroundColor: 'rgba(125,0,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(125,0,0,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  constructor(
    public deviceMeasurementsDailyService: DevicemeasurementsdailyService,
    public loadingService: LoadingService,
    private deviceCardDataService: DevicecarddataService,

  ) { }

  ngOnInit() {
      this.showCharts = false;
      this.showChartsPending = false;
      this.deviceId = this.device;
      // console.log(this.deviceId);
      this.deviceData = this.deviceCardDataService.getSingleDeviceCard(this.deviceId).valueChanges();
      this.success = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success');
      this.successTint = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-tint');
      this.successShade = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-shade');
      //
      this.subscription.add(this.deviceMeasurementsDailyService.getDeviceMeasurementsDailySummary7Days(this.deviceId)
        .subscribe(summaryData => {
          this.getGraphData7Days(summaryData);
        })
      );
  }

  async ngAfterContentInit() {
    this.loadingService.stopLoading();

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



  getGraphData7Days(summaryData) {
    this.cond1Data = [];
    const cond1Min = [];
    const cond1Ave = [];
    const cond1Max = [];
    const cond2Min = [];
    const cond2Ave = [];
    const cond2Max = [];
    const xAxisLabels = [];
    summaryData.forEach( record => {
      const dt = new Date(record.queryStopTime * 1000);
      const dtFormatted = dt.toLocaleString('default', { month: 'short' }) + '-' + dt.getDate();
      xAxisLabels.push(dtFormatted);
      cond1Min.push(record.cond1compensated_min);
      cond1Ave.push(record.cond1compensated_ave);
      cond1Max.push(record.cond1compensated_max);
      cond2Min.push(record.cond2compensated_min);
      cond2Ave.push(record.cond2compensated_ave);
      cond2Max.push(record.cond2compensated_max);
    });
    const cond1MinSeries = { data: cond1Min, label: 'Min' };
    const cond1AveSeries = { data: cond1Ave, label: 'Ave' };
    const cond1MaxSeries = { data: cond1Max, label: 'Max' };
    const cond2MinSeries = { data: cond2Min, label: 'Min' };
    const cond2AveSeries = { data: cond2Ave, label: 'Ave' };
    const cond2MaxSeries = { data: cond2Max, label: 'Max' };

    this.cond1Data.push(cond1MinSeries);
    this.cond1Data.push(cond1AveSeries);
    this.cond1Data.push(cond1MaxSeries);
    this.cond2Data.push(cond2MinSeries);
    this.cond2Data.push(cond2AveSeries);
    this.cond2Data.push(cond2MaxSeries);
    this.chartLabels = xAxisLabels;
    if (this.chartLabels.length >= 3 ) {
      this.showCharts = true;
      this.showChartsPending = false;
    } else {
      this.showChartsPending = true;
      this.showCharts = false;
    }

    console.log(`length: ${this.chartLabels.length}`);
  }

} // closes class
