import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription} from 'rxjs';
import { DevicecarddataService } from '../../../_services/devicecarddata.service';
import { DevicemeasurementsdailyService } from '../../../_services/devicemeasurementsdaily.service';
import { DevicemeasurementsService } from '../../../_services/devicemeasurements.service';

// For ng2-charts
import { ChartDataSets, ChartOptions, ChartData } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

// Modal temp
import { TooltipComponent } from '../../../_components/modals/tooltip/tooltip.component';
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();
  // @Input() data;
  @Input() device;
  deviceData: any;
  deviceId: any;
  success: string;
  successTint: string;
  successShade: string;
  cond1Data: ChartDataSets[] = [];
  temp1Data: ChartDataSets[] = [];
  cond1RealTimeData: ChartDataSets[] = [];
  chartLabels: Label[] = [];
  chartLabelsRealTimeData: Label[] = [];
  //
  showCharts: boolean; // display the charts
  showChartsPending: boolean; // show data pending if not sufficient data
  showHourly: boolean;

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'white',
          }
        }
      ]
    },
    annotation: {

    },
  };
  public lineChartColors: Color[] = [
    // graphData array is min, ave, max...so colors are green, gray, red
    { // minimum condition => green
      backgroundColor: 'transparent',
      borderColor: 'rgba(0,125,0,1)',
      pointBackgroundColor: 'rgba(0,125,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,125,0,0.8)'
    },
    { // average condition => gray
      backgroundColor: 'transparent',
      borderColor: 'rgba(204,204,204,1)',
      pointBackgroundColor: 'rgba(204,204,204,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(204,204,204,1)'
    },
    { // max condition => red
      backgroundColor: 'transparent',
      borderColor: 'rgba(125,0,0,1)',
      pointBackgroundColor: 'rgba(125,0,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(125,0,0,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  constructor(
    public deviceMeasurementsDailyService: DevicemeasurementsdailyService,
    public deviceCardDataService: DevicecarddataService,
    public deviceMeasurementsService: DevicemeasurementsService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.showCharts = false;
    this.showChartsPending = false;
    this.showHourly = false;
    this.deviceId = this.device;
    // console.log(this.deviceId);
    this.deviceData = this.deviceCardDataService.getSingleDeviceCard(this.deviceId).valueChanges();
    this.success = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success');
    this.successTint = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-tint');
    this.successShade = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-shade');
    //
    this.subscription.add(this.deviceMeasurementsDailyService.getDeviceMeasurementsDailySummary7Days(this.deviceId)
      .subscribe(summaryData => {
        this.getGraphData7Days(summaryData);
      })
    );
    this.subscription.add(this.deviceMeasurementsService.getDeviceMeasurementsLastNHours(this.deviceId, 8)
      .subscribe(deviceMeasurements => {
        this.getRecentDeviceMeasurements(deviceMeasurements);
      })
    );
  }
  getGraphData7Days(summaryData) {
    this.cond1Data = [];
    const cond1Min = [];
    const cond1Ave = [];
    const cond1Max = [];
    const temp1Min = [];
    const temp1Ave = [];
    const temp1Max = [];
    const xAxisLabels = [];
    summaryData.forEach( record => {
      const dt = new Date(record.queryStopTime * 1000);
      const dtFormatted = dt.toLocaleString('default', { month: 'short' }) + '-' + dt.getDate();
      xAxisLabels.push(dtFormatted);
      cond1Min.push(record.cond1compensated_min);
      cond1Ave.push(record.cond1compensated_ave);
      cond1Max.push(record.cond1compensated_max);
      temp1Min.push(record.temp1calc_min);
      temp1Ave.push(record.temp1calc_ave);
      temp1Max.push(record.temp1calc_max);
    });
    const cond1MinSeries = { data: cond1Min, label: 'Min' };
    const cond1AveSeries = { data: cond1Ave, label: 'Ave' };
    const cond1MaxSeries = { data: cond1Max, label: 'Max' };
    const temp1MinSeries = { data: temp1Min, label: 'Min' };
    const temp1AveSeries = { data: temp1Ave, label: 'Ave' };
    const temp1MaxSeries = { data: temp1Max, label: 'Max' };

    this.cond1Data.push(cond1MinSeries);
    this.cond1Data.push(cond1AveSeries);
    this.cond1Data.push(cond1MaxSeries);
    this.temp1Data.push(temp1MinSeries);
    this.temp1Data.push(temp1AveSeries);
    this.temp1Data.push(temp1MaxSeries);
    this.chartLabels = xAxisLabels;
    if (this.chartLabels.length >= 3 ) {
      this.showCharts = true;
      this.showChartsPending = false;
    } else {
      this.showChartsPending = true;
      this.showCharts = false;
    }
  }
  getRecentDeviceMeasurements(deviceMeasurements) {
    this.cond1RealTimeData = [];
    const cond1Values = [];
    const xAxisLabels = [];
    deviceMeasurements.forEach(deviceMeasurement => {
      const dt = new Date(deviceMeasurement.timestamp.seconds * 1000);
      const dtFormatted = dt.getHours() + ':' + dt.getMinutes(); // toLocaleString('default', { month: 'short' }) + '-' + dt.getDate();
      xAxisLabels.push(dtFormatted);
      cond1Values.push(deviceMeasurement.cond1compensated);

    });
    const cond1RealTimeDataSeries = { data: cond1Values, label: 'Conductivity' };
    this.chartLabelsRealTimeData = xAxisLabels;
    this.cond1RealTimeData.push(cond1RealTimeDataSeries);
    this.showHourly = true;
  }

  async openModal(tooltipslug) {
    const modal = await this.modalController.create({
      component: TooltipComponent,
      componentProps: {
        slug: tooltipslug,
     }
    });
    return await modal.present();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
} // closes class
