import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './_services/auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appVersion: string;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Account',
      url: '/account',
      icon: 'person'
    },
    {
      title: 'Support',
      url: '/support',
      icon: 'help-circle'
    }
    /* ,{  // commented out, but left in router so the page is still accessible.
      title: 'Lab Tests',
      url: '/labtest',
      icon: 'flask'
    }
    */
  ];

  user: Observable<firebase.User>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService
  ) {
    this.initializeApp();
    this.user = this.authService.user;
    if (this.platform.is('cordova')) {
      this.appPages.push(    {
        title: 'Set up WaterBot',
        url: '/ble',
        icon: 'bluetooth'
      });
    }
    this.appVersion = environment.appVersion;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  doSignout() {
    this.authService.trySignout();
  }

} // closes class
