import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(public alertController: AlertController) {}

  async createAlert(headerText, messageText, buttonText) {
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      buttons: [buttonText],
      animated: true
    });
    await alert.present();
  }
}
