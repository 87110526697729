import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor(
    public afs: AngularFirestore
  ) { }
  getTooltip(slug) {
    return this.afs.collection('waterfaq', ref => ref.where('slug', '==', slug));
  }
  getTooltipBySlug(slug) {
    return this.afs.collection('waterfaq').doc(slug);
  }
}
