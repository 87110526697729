import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DeviceCardData } from '../_interfaces/devicecarddata';
import { DeviceProfile } from '../_interfaces/deviceprofile';

@Injectable({
  providedIn: 'root'
})
export class DevicecarddataService {

  constructor(
    public afs: AngularFirestore
  ) { }

  getUserDeviceCards(userId: string): AngularFirestoreCollection<DeviceCardData> {
    return this.afs.collection('deviceCardData', ref => ref.where('authorizedusers', 'array-contains', userId));
  }
  getDeviceProfileInfo(deviceId: string): AngularFirestoreDocument<DeviceProfile> {
    return this.afs.doc(`deviceprofile/${deviceId}`);
  }
  updateDeviceProfileInfo(
    serialnum: string,
    prettyname: string,
    watertype: string,
    waterprovider: string,
    port1src: string,
    port2src: string,
    ) {
    return this.afs.doc(`deviceprofile/${serialnum}`).set({
      serialnum,
      prettyname,
      watertype,
      waterprovider,
      port1src,
      port2src
    }, {merge: true});

  }
  getSingleDeviceCard(deviceId: string) {
    return this.afs.doc(`deviceCardData/${deviceId}`);
  }
} // closes class
