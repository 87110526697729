import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

    private loading: HTMLIonLoadingElement;
    private isShowing = false;

    currentLoading = null;

  constructor(
    public loadingController: LoadingController
  ) { }

// https://stackoverflow.com/questions/52574448/ionic-4-loading-controller-dismiss-is-called-before-present-which-will-ke
async startLoading() {
  // Dismiss previously created loading
  if (await this.currentLoading != null) {
      await this.currentLoading.dismiss();
  }

  this.currentLoading = await this.loadingController.create({
    message: 'Loading...',
    spinner: 'dots',
  });
  return await this.currentLoading.present();
}
async startLoadingWithMessage(loaderMessage) {
  console.log(this.currentLoading);
  // Dismiss previously created loading
  if (await this.currentLoading != null) {
    await this.currentLoading.dismiss();
  }
  this.currentLoading = await this.loadingController.create({
    message: loaderMessage,
    spinner: 'dots',
  });
  return await this.currentLoading.present();
}
/*
  async startLoading(): Promise<void> {
    if (!this.isShowing) {
        this.loading = await this.loadingController.create({
          message: 'Loading...',
          spinner: 'dots',
          cssClass: 'custom-loader-class'
        });
        this.isShowing = true;
        return await this.loading.present();
    } else {
        // If loader is showing, only change text, won't create a new loader.
        this.isShowing = true;
        // this.loading.message = message;
    }
  }

  async startLoadingWithMessage(loaderMessage): Promise<void> {
    if (!this.isShowing) {
        this.loading = await this.loadingController.create({
          message: loaderMessage,
          spinner: 'dots',
          cssClass: 'custom-loader-class',
          backdropDismiss: true
        });
        this.isShowing = true;
        return await this.loading.present();
    } else {
        // If loader is showing, only change text, won't create a new loader.
        this.isShowing = true;
        // this.loading.message = message;
    }
  }
*/
  /*
  async stopLoading(): Promise<void> {
    if (this.isShowing) {
    await this.loading.onDidDismiss();
    }
  }
*/
  async stopLoading() {
    if (this.currentLoading != null) {
      await this.loadingController.dismiss();
      this.currentLoading = null;
    }
    return;
}
}
