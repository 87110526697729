import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Devicemeasurementsdailysummary } from '../_interfaces/devicemeasurementsdailysummary';
import { DeviceProfile } from '../_interfaces/deviceprofile';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevicemeasurementsdailyService {

  sevenDayTimestamp: any;
  thirtyDayTimestamp: any;

  constructor(
    public afs: AngularFirestore
  ) {
    this.sevenDayTimestamp = Date.now() / 1000 - (7 * 86400);
    this.thirtyDayTimestamp = Date.now() / 1000 - (30 * 86400);
  }

  getDeviceMeasurementsDailySummary7Days(deviceId): Observable<any> {
    return this.afs.collection('devicemeasurementsdailysummary', ref =>
      ref
      .where('device', '==', deviceId)
      .where('queryStopTime', '>', this.sevenDayTimestamp)
      .orderBy('queryStopTime', 'asc')
    ).valueChanges();
  }
  getDeviceMeasurementsDailySummary30Days(deviceId): Observable<any> {
    return this.afs.collection('devicemeasurementsdailysummary', ref =>
      ref
      .where('device', '==', deviceId)
      .where('queryStopTime', '>', this.thirtyDayTimestamp)
      .orderBy('queryStopTime', 'asc')
    ).valueChanges();
  }

} // closes class
